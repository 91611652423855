import Form from './Components/Form';
import './App.css';

function App() {
  return (
    <div >
      <Form></Form>
    </div>
  );
}

export default App;

