
import React from 'react'

function ProductType({ formData, setFormData }) {
  return (
    <form className="product_info" >
      
      <div>
        <input type="radio" value="Term Life Insurance" name="product_type"   onChange={(event) =>
          setFormData({ ...formData, product_type: event.target.value })
        }/>Term Life Insurance
      </div>
      
        <div>     
          <input type="radio" value="Whole Life Insurance" name="product_type" onChange={(event) =>
          setFormData({ ...formData, product_type: event.target.value })
        }/>Whole Life Insurance
        </div>
   
        <div >
          <input type="radio" value="Mortgage Protection" name="product_type" onChange={(event) =>
          setFormData({ ...formData, product_type: event.target.value })
        }/>Mortgage Protection
        </div>

        <div >
          <input type="radio" value="Critical Illness Insurance" name="product_type" onChange={(event) =>
          setFormData({ ...formData, product_type: event.target.value })
        }/>Critical Illness Insurance
        </div>

        <div >
          <input type="radio" value="Disability Insurance" name="product_type" onChange={(event) =>
          setFormData({ ...formData, product_type: event.target.value })
        }/>Disability Insurance
        </div>

        <div >
          <input type="radio" value="No Medical Insurance" name="product_type" onChange={(event) =>
          setFormData({ ...formData, product_type: event.target.value })
        }/>No Medical Insurance
        </div>

        <div >
          <input type="radio" value="Children Insurance" name="product_type" onChange={(event) =>
          setFormData({ ...formData, product_type: event.target.value })
        }/>Children Insurance
        </div>

        <div >
          <input type="radio" value="Health Insurance" name="product_type" onChange={(event) =>
          setFormData({ ...formData, product_type: event.target.value })
        }/>Health Insurance
        </div>

        <div >
          <input type="radio" value="Travel Insurance" name="product_type" onChange={(event) =>
          setFormData({ ...formData, product_type: event.target.value })
        }/>Travel Insurance
        </div>

        <div >
          <input type="radio" value="Group Benefits Insurance" name="product_type" onChange={(event) =>
          setFormData({ ...formData, product_type: event.target.value })
        }/>Group Benefits Insurance
        </div>
     
    </form>
  )
}

export default ProductType