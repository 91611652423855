import React from 'react'

function BasicInfo({ formData, setFormData }) {
  return (
    <form className="basic_info" >

      <div className="row">  
      
        <div className="title" >Gender</div>   
         
         <input type="radio" value="Female" name="gender" onChange={(event) =>
          setFormData({ ...formData, gender: event.target.value })
        }  />Female
      
        <input type="radio" value="Male" name="gender" onChange={(event) =>
          setFormData({ ...formData, gender: event.target.value })
        } />Male

      </div>
       
      <div className="row">
        <div className="title">Smoker ? </div>
        <input type="radio" value="Do" name="smoker" onChange={(event) =>
          setFormData({ ...formData, smoker: event.target.value })
        }  />Do
        <input type="radio" value="Do Not" name="smoker" onChange={(event) =>
          setFormData({ ...formData, smoker: event.target.value })
        }  /> Do not
      </div>
 
      <div className="row">
        <div className="title">Coverage</div>
        <input type="radio" value="Myself" name="coverage" onChange={(event) =>
          setFormData({ ...formData, coverage: event.target.value })
        }  /> Myself
        <input type="radio" value="My spouse and myself" name="coverage" onChange={(event) =>
          setFormData({ ...formData, coverage: event.target.value })
        } />My spouse and myself
      </div>
      
      <div className="row">
        <div className="title">Residency</div>
        <input type="radio" value="Permanent Residence" name="residency" onChange={(event) =>
          setFormData({ ...formData, residency: event.target.value })
        }  />Permanent Residence

        <input type="radio" value="Visiting" name="residency" onChange={(event) =>
          setFormData({ ...formData, residency: event.target.value })
        } />Visiting
        
      </div>

      <div className="row">
        <label className="title">Postal Code:</label>
        <br/>
        <input type="text" name="postal_code"  value={formData.postal_code} onChange={(event) =>
          setFormData({ ...formData, postal_code: event.target.value })
        }></input>
       </div>

    </form>
  )
}

export default BasicInfo