import React from 'react'

function CoverageAmount({ formData, setFormData }) {
  return (
    
      <form className="coverage_amount">
      
        <label className="title-amount" >Coverage Amount:</label>
        <br/>
        <input type="text" name="coverage_amount"  value={formData.coverage_amount} onChange={(event) =>
          setFormData({ ...formData, coverage_amount: event.target.value })
        }></input>
       
      </form>
      
    
  )
}

export default CoverageAmount
